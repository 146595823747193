import { ChangeEvent, FC, useEffect, useState } from 'react';
import cn from 'classnames';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import css from './user-list.module.scss';
import { Button } from '../../components/button/button.component';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { usePermission } from '../../contexts/permission.context';
import {
  DeviceUserSimpleDto,
  DeviceUserSortField,
  GetDeviceUserListRequestDto,
  PaginationSortOrder,
  Permission,
  SearchDeviceUserByConditionDto
} from '../../../types/api';
import { getDeviceUserList } from '../../../api/device-users';
import { PaginationRowsPerPageOptionsDeviceUsers } from '../../../const/pagination.const';
import { DeviceUsersUploadModal } from './components/device-users-upload-modal/device-users-upload-modal.component';
import { TextInput, TextInputType } from '../../components/text-input/text-input.component';
import { useDebounce } from 'use-debounce';

export const DeviceUserListComponent: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const [users, setUsers] = useState<DeviceUserSimpleDto[]>([]);
  const [usersCount, setUsersCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);
  const [sortField, setSortField] = useState<DeviceUserSortField | undefined>(undefined);
  const [filterText, setFilterText] = useState<string>('');
  const [debouncedFilterText] = useDebounce(filterText, 1000);
  const [condition, setCondition] = useState<SearchDeviceUserByConditionDto[]>([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);

  const updateData = async (request: Partial<GetDeviceUserListRequestDto> = {}) => {
    const conditions = request.conditions || condition;
    const response = await getDeviceUserList({
      conditions: conditions,
      page: currentPage,
      limit: rowsPerPage,
      sort_order: sortOrder,
      sort_field: sortField
    });
    setUsers(response.users);
    setUsersCount(response.count);
  };

  const handleRowClicked = (row: DeviceUserSimpleDto) => {
    navigate(row.id);
  };
  const handleChangePage = async (value: number) => {
    setCurrentPage(value);
  };

  const handleSort = async (
    selectedColumn: TableColumn<DeviceUserSimpleDto>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField) {
      setSortField(selectedColumn.sortField as DeviceUserSortField);
    }
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      setCurrentPage(1);
    }
    setRowsPerPage(value);
  };

  const handleUploadModalCancel = () => setIsUploadModalOpen(false);
  const handleUploadModalSuccess = () => {
    void updateData();
    setIsUploadModalOpen(false);
  };

  useEffect(() => {
    if (debouncedFilterText) {
      const conditions: SearchDeviceUserByConditionDto[] = [
        {
          criteria: 'username',
          value: debouncedFilterText
        },
        {
          criteria: 'email',
          value: debouncedFilterText
        },
        {
          criteria: 'employee_name',
          value: debouncedFilterText
        },
        {
          criteria: 'position',
          value: debouncedFilterText
        },
        {
          criteria: 'phone_number',
          value: debouncedFilterText
        },
        {
          criteria: 'computers_count',
          value: debouncedFilterText
        },
        {
          criteria: 'mobile_devices_count',
          value: Number.isNaN(Number(debouncedFilterText))
            ? ''
            : Number(debouncedFilterText).toString()
        }
      ];
      setCondition(conditions);
      void updateData({ page: 1, conditions }).then(() => {
        setCurrentPage(1);
      });
    } else {
      setCondition([]);
      void updateData({ page: 1, conditions: [] }).then(() => {
        setCurrentPage(1);
      });
    }
  }, [debouncedFilterText]);

  const deviceUsersColumns: TableColumn<DeviceUserSimpleDto>[] = [
    {
      name: t('device_users.user_list.column_username'),
      selector: (row: DeviceUserSimpleDto) => row.username,
      sortable: true,
      sortField: DeviceUserSortField.Username,
      maxWidth: '400px',
      style: { cursor: 'pointer' }
    },
    {
      name: t('device_users.user_list.column_email'),
      selector: (row: DeviceUserSimpleDto) => row?.email || '---',
      sortable: true,
      sortField: DeviceUserSortField.Email,
      maxWidth: '400px',
      style: { cursor: 'pointer' }
    },
    {
      name: t('device_users.user_list.column_employee_name'),
      selector: (row: DeviceUserSimpleDto) => row?.employee_name || '---',
      sortable: true,
      sortField: DeviceUserSortField.EmployeeName,
      maxWidth: '400px',
      style: { cursor: 'pointer' }
    },
    {
      name: t('device_users.user_list.column_phone_number'),
      selector: (row: DeviceUserSimpleDto) => row?.phone_number || '---',
      maxWidth: '400px',
      style: { cursor: 'pointer' }
    },
    {
      name: t('device_users.user_list.column_position'),
      selector: (row: DeviceUserSimpleDto) => row?.position || '---',
      sortable: true,
      sortField: DeviceUserSortField.Position,
      maxWidth: '400px',
      style: { cursor: 'pointer' }
    },
    {
      name: t('device_users.user_list.column_computers_count'),
      selector: (row: DeviceUserSimpleDto) =>
        typeof row?.computers_count === 'number' ? row.computers_count : '',
      sortable: true,
      sortField: DeviceUserSortField.ComputersCount,
      maxWidth: '400px',
      style: { cursor: 'pointer' }
    },
    {
      name: t('device_users.user_list.column_mobile_devices_count'),
      selector: (row: DeviceUserSimpleDto) =>
        typeof row?.mobile_devices_count === 'number' ? row.mobile_devices_count : '',
      sortable: true,
      sortField: DeviceUserSortField.MobileDevicesCount,
      maxWidth: '400px',
      style: { cursor: 'pointer' }
    }
  ];

  const handleClearFilter = () => {
    if (filterText) setFilterText('');
  };

  const handleUpdateFilter = async (e: ChangeEvent) => {
    return e.target ? setFilterText((e.target as HTMLInputElement).value) : null;
  };

  useEffect(() => {
    void updateData();
  }, [currentPage, rowsPerPage, sortOrder, sortField]);

  return (
    <div className={cn(css.Root, 'card-body', 'p-0')}>
      <Breadcrumbs />
      <>
        <div className={css.Actions}>
          <Link to="new">
            <Button
              isDisabled={!isAllowedTo(Permission.EditDeviceUsers)}
              className={css.ActionBtn}
              theme="primary"
            >
              {t('device_users.buttons.new')}
            </Button>
          </Link>
          <Button
            isDisabled={!isAllowedTo(Permission.EditDeviceUsers)}
            theme="secondary"
            className={css.ActionBtn}
            onClick={() => setIsUploadModalOpen(true)}
          >
            {t('device_users.buttons.import_csv')}
          </Button>
          <div className={css.Filter}>
            <TextInput
              className={css.FilterInput}
              type={TextInputType.TEXT}
              value={filterText}
              onChange={handleUpdateFilter}
              placeholder={t('static_groups.page.assignments_tab.filter.message.device_users')}
            />
            <Button className={css.FilterClearButton} onClick={handleClearFilter}>
              {t('static_groups.page.assignments_tab.filter.clear_btn')}
            </Button>
          </div>
        </div>
      </>
      {users && (
        <DataTable
          onRowClicked={handleRowClicked}
          noDataComponent={t('common.no_records_in_table')}
          columns={deviceUsersColumns}
          data={users}
          pagination
          paginationServer
          paginationDefaultPage={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={usersCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptionsDeviceUsers}
          paginationComponentOptions={{
            rowsPerPageText: t('common.table.rows_per_page'),
            rangeSeparatorText: t('common.table.of')
          }}
          sortServer
          onSort={handleSort}
        />
      )}
      <DeviceUsersUploadModal
        isOpen={isUploadModalOpen}
        onCancel={handleUploadModalCancel}
        onRequestClose={handleUploadModalSuccess}
      />
    </div>
  );
};
