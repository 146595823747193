import { ScopeOptionType } from '../types/api';
import i18next from '../i18n/i18n';

export const scopeOptionTitles: Record<ScopeOptionType, string> = {
  [ScopeOptionType.Device]: i18next.t('common.scope_types.device'),
  [ScopeOptionType.StaticGroupDevices]: i18next.t('common.scope_types.smart_group_devices'),
  [ScopeOptionType.SmartGroupDevices]: i18next.t('common.scope_types.static_group_devices'),
  [ScopeOptionType.DeviceUser]: i18next.t('common.scope_types.device_user'),
  [ScopeOptionType.StaticGroupDeviceUsers]: i18next.t(
    'common.scope_types.static_group_device_users'
  ),
  [ScopeOptionType.SmartGroupDeviceUsers]: i18next.t('common.scope_types.smart_group_device_users')
};
