import { FC, useEffect, useState } from 'react';
import css from './criteria-tab.module.scss';
import { TextInput } from '../../../../components/text-input/text-input.component';
import DataTable from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import {
  CreateSmartGroupDeviceUsersConditionRequestDto,
  DeviceUserCriteria,
  DeviceUsersCriteriaValue
} from '../../../../../types/api';
import { Dropdown, DropdownOption } from '../../../../components/dropdown/dropdown.component';
import { Button } from '../../../../components/button/button.component';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { deviceUserCriteriaTitles } from '../../../../../const/criteria-titles.const';
import { SmartGroupDeviceUsersFormValues } from '../smart-group-devices-form/smart-group-device-users-form.schema';
import {
  getDeviceUsersCriteriaOptions,
  getDeviceUsersCriteriaValues
} from '../../../../../api/device-users';
import { WithClassname } from '../../../../../types/common';
import cn from 'classnames';

interface IProps {
  form: UseFormReturn<SmartGroupDeviceUsersFormValues>;
  disabled?: boolean;
}

export const CriteriaTab: FC<IProps & WithClassname> = (props) => {
  const { className, form, disabled = false } = props;
  const { control, register, setFocus, setValue } = form;
  const { t } = useTranslation();

  const [addCriteriaShown, setAddCriteriaShown] = useState(false);
  const [chooseCriteriaValueShown, setChooseCriteriaValueShown] = useState(false);
  const [chosenCriterionIndex, setChosenCriterionIndex] = useState<number | undefined>(undefined);
  const [criteria, setCriteria] = useState<DeviceUserCriteria[]>();
  const [criteriaValues, setCriteriaValues] = useState<DeviceUsersCriteriaValue[]>();
  const [groupCriteriaValues, setGroupCriteriaValues] = useState<DeviceUsersCriteriaValue[]>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'criteria'
  });
  const andOrOptions: DropdownOption[] = [
    { text: t('smart_groups.page.criteria_tab.new_criteria.and_or.and'), value: 'and' },
    { text: t('smart_groups.page.criteria_tab.new_criteria.and_or.or'), value: 'or' }
  ];
  const startBracketOptions: DropdownOption[] = [
    { text: '', value: 'false' },
    { text: '(', value: 'true' }
  ];
  const endBracketOptions: DropdownOption[] = [
    { text: '', value: 'false' },
    { text: ')', value: 'true' }
  ];
  const textOperatorOptions: DropdownOption[] = [
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.is'), value: '=' },
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.is_not'), value: '!=' },
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.like'), value: 'like' },
    { text: t('smart_groups.page.criteria_tab.new_criteria.operator.not_like'), value: 'not like' }
  ];
  const groupOperatorOptions: DropdownOption[] = [
    {
      text: t('smart_groups.page.criteria_tab.new_criteria.operator.member_of'),
      value: 'member of'
    },
    {
      text: t('smart_groups.page.criteria_tab.new_criteria.operator.not_member_of'),
      value: 'not member of'
    }
  ];

  const fetchGroupCriteriaValues = async () => {
    if (!form) return;
    if (groupCriteriaValues && groupCriteriaValues.length > 0) return;
    const isViewMode = disabled;
    const values = form.getValues('criteria');
    const hasGroupCriteria = values?.some((i) => i.criteria === DeviceUserCriteria.Group);
    if (!isViewMode || (isViewMode && hasGroupCriteria)) {
      const response = await getDeviceUsersCriteriaValues({ criteria: DeviceUserCriteria.Group });
      setGroupCriteriaValues(response.values);
    } else {
      setGroupCriteriaValues([]);
    }
  };

  const handleSelectAddedCriteria = () => {
    setAddCriteriaShown(true);
  };
  const handleAddCriteria = (criterion: DeviceUserCriteria) => async () => {
    const newCriteria: CreateSmartGroupDeviceUsersConditionRequestDto = {
      conjunctive: fields?.length ? 'and' : null,
      criteria: criterion,
      operator: criterion === DeviceUserCriteria.Group ? 'member of' : '=',
      value:
        criterion === DeviceUserCriteria.Group && groupCriteriaValues
          ? groupCriteriaValues.find(Boolean)?.value || ''
          : '',
      start_bracket: false,
      end_bracket: false
    };
    append(newCriteria);
    setAddCriteriaShown(false);
    if (criterion === DeviceUserCriteria.Group) {
      await fetchGroupCriteriaValues();
    }
  };

  const handleDeleteCriteria = (index: number) => () => {
    if (index === 0 && fields?.length > 1) {
      setValue('criteria.1.conjunctive', null);
      fields[1].conjunctive = null;
    }
    remove(index);
  };

  const handleChooseCriteriaValue =
    (criterion: CreateSmartGroupDeviceUsersConditionRequestDto, index: number) => async () => {
      const response = await getDeviceUsersCriteriaValues({
        criteria: criterion.criteria as DeviceUserCriteria
      });
      setCriteriaValues(response.values);
      setChosenCriterionIndex(index);
      setChooseCriteriaValueShown(true);
    };

  const handleAddCriteriaValue = (value: DeviceUsersCriteriaValue) => () => {
    if (chosenCriterionIndex == null) return;
    setValue(`criteria.${chosenCriterionIndex}.value`, value.value);
    setChooseCriteriaValueShown(false);
  };

  const handleCancelAddingCriteriaValue = () => {
    setChooseCriteriaValueShown(false);
  };

  const groupOptions: DropdownOption[] =
    groupCriteriaValues?.map((i) => ({ text: i.title || '', value: i.value })) || [];
  const criteriaValue = form.watch('criteria');
  const columns: TableColumn<CreateSmartGroupDeviceUsersConditionRequestDto>[] = [
    {
      name:
        criteriaValue && criteriaValue.length > 1
          ? t('smart_groups.page.criteria_tab.new_criteria.heading.and_or')
          : '',
      cell: (row, index) => {
        if (!index) return <></>;
        return (
          <Dropdown
            className={css.CellDropdown}
            options={andOrOptions}
            register={register(`criteria.${index}.conjunctive`)}
            disabled={disabled}
          />
        );
      },
      style: { minWidth: 0 }
    },
    {
      name: '',
      cell: (row, index) => (
        <Dropdown
          className={css.CellDropdown}
          options={startBracketOptions}
          register={register(`criteria.${index}.start_bracket`)}
          disabled={disabled}
        />
      )
    },
    {
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.criteria'),
      selector: (row) => deviceUserCriteriaTitles[row.criteria] || row.criteria
    },
    {
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.operator'),
      cell: (row, index) => (
        <Dropdown
          className={css.CellDropdown}
          options={
            row.criteria === DeviceUserCriteria.Group ? groupOperatorOptions : textOperatorOptions
          }
          register={register(`criteria.${index}.operator`)}
          disabled={disabled}
        />
      )
    },
    {
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.value'),
      cell: (row, index) => (
        <>
          {row.criteria === DeviceUserCriteria.Group && (
            <Dropdown
              options={groupOptions}
              className={css.CellValueDropdown}
              register={register(`criteria.${index}.value`)}
              disabled={disabled}
            />
          )}
          {row.criteria !== DeviceUserCriteria.Group && (
            <TextInput
              className={css.CellTextInput}
              register={register(`criteria.${index}.value`)}
              disabled={disabled}
              required
            />
          )}
        </>
      )
    },
    {
      name: '',
      cell: (row, index) => (
        <>
          {row.criteria !== DeviceUserCriteria.Group && (
            <Button
              className={css.ChooseCriteriaValueButton}
              isDisabled={disabled}
              onClick={handleChooseCriteriaValue(row, index)}
            >
              ...
            </Button>
          )}
        </>
      )
    },
    {
      name: '',
      cell: (row, index) => (
        <Dropdown
          className={css.CellDropdown}
          options={endBracketOptions}
          register={register(`criteria.${index}.end_bracket`)}
          disabled={disabled}
        />
      )
    },
    {
      cell: (row, index) =>
        !disabled && (
          <Button
            className={css.AddCriteriaCellButton}
            type="button"
            theme="danger"
            onClick={handleDeleteCriteria(index)}
          >
            {t('smart_groups.page.criteria_tab.new_criteria.delete_btn')}
          </Button>
        ),
      style: { justifyContent: 'end' }
    }
  ];

  const criteriaColumns: TableColumn<DeviceUserCriteria>[] = [
    {
      name: t('smart_groups.page.criteria_tab.table.header.new_criteria'),
      selector: (row) => deviceUserCriteriaTitles[row] || row
    },
    {
      cell: (row) => (
        <Button type="button" theme="success" onClick={handleAddCriteria(row)}>
          {t('smart_groups.page.criteria_tab.choose_btn')}
        </Button>
      ),
      style: { justifyContent: 'end' }
    }
  ];

  const criteriaValuesColumn: TableColumn<DeviceUsersCriteriaValue>[] = [
    {
      name: t('smart_groups.page.criteria_tab.table.header.choose_value'),
      selector: (value) => value.value
    },
    {
      cell: (value) => (
        <Button type="button" theme="success" onClick={handleAddCriteriaValue(value)}>
          {t('smart_groups.page.criteria_tab.choose_btn')}
        </Button>
      ),
      style: { justifyContent: 'end' }
    }
  ];

  const fetchCriteria = async () => {
    const result = await getDeviceUsersCriteriaOptions();
    setCriteria(result.criteria);
  };

  useEffect(() => {
    void fetchCriteria();
  }, []);

  useEffect(() => {
    if (!form) return;
    void fetchGroupCriteriaValues();
  }, [form || disabled]);

  useEffect(() => {
    setFocus(`criteria.${fields.length - 1}.value`);
  }, [fields, setFocus]);

  return (
    <div className={cn(className, css.Root)}>
      {addCriteriaShown && criteria ? (
        <DataTable
          columns={criteriaColumns}
          data={criteria}
          noDataComponent={t('common.no_records_in_table')}
        />
      ) : (
        <>
          {chooseCriteriaValueShown && criteriaValues ? (
            <div>
              <Button onClick={handleCancelAddingCriteriaValue} theme="danger">
                {t('smart_groups.page.criteria_tab.back_btn')}
              </Button>
              <DataTable columns={criteriaValuesColumn} data={criteriaValues} />
            </div>
          ) : (
            <>
              {!disabled && (
                <Button theme="primary" onClick={handleSelectAddedCriteria}>
                  {t('smart_groups.page.criteria_tab.add_btn')}
                </Button>
              )}
              {fields && groupCriteriaValues && (
                <DataTable
                  columns={columns}
                  data={fields}
                  noDataComponent={t('common.no_records_in_table')}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
