import { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { TextInput, TextInputType } from '../../../components/text-input/text-input.component';
import css from './settings-ldap.module.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LdapInstanceFullDto, Permission } from '../../../../types/api';
import { Button } from '../../../components/button/button.component';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { usePermission } from '../../../contexts/permission.context';
import { LdapForm, ldapFormSchema } from './settings-ldap.schema';
import { Checkbox } from '../../../components/checkbox/checkbox.component';
import { getCurrentLdapInstance, saveLdapInstance, testLdapConnection } from '../../../../api/ldap';
import useRequest from '../../../../hooks/useRequest';
import { toast } from 'react-toastify';
import { Collapsible } from '../../../components/collapsible/collapsible.component';
import { Modal } from '../../../components/modal/modal.component';
import { syncDeviceUsersViaLDAP } from '../../../../api/device-users';
import { FileInput } from '../../../components/file-input/file-input.component';

export const SettingsLdap: FC = () => {
  const [hasInstance, setHasInstance] = useState<boolean | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'update'>('view');
  const [isPasswordEditable, setIsPasswordEditable] = useState(true);
  const [hasCaCertificate, setHasCaCertificate] = useState(false);
  const [isCaCertificateEditable, setIsCaCertificateEditable] = useState(false);
  const [testConnectionResult, setTestConnectionResult] = useState<boolean | null>(null);
  const [isSyncModalOpen, setIsSyncModalOpen] = useState(false);
  const instanceRef = useRef<LdapInstanceFullDto>();
  const { register, formState, trigger, getValues, reset, setValue, watch } = useForm<LdapForm>({
    mode: 'onChange',
    resolver: yupResolver(ldapFormSchema),
    defaultValues: ldapFormSchema.getDefault()
  });
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const initRequest = useRequest<LdapInstanceFullDto>();
  const saveRequest = useRequest<LdapInstanceFullDto>();
  const testConnectionRequest = useRequest();
  const syncRequest = useRequest();

  const resetForm = (instance?: LdapInstanceFullDto) => {
    if (instance) {
      reset({
        display_name: instance.display_name,
        hostname: instance.hostname,
        ssl: instance.ssl,
        port: instance.port || '',
        base_dn: instance.base_dn || '',
        filter: instance.filter || '',
        user_dn: instance.user_dn || '',
        password: instance.password || '',
        sync_enabled: instance.sync_enabled,
        sync_interval: instance.sync_interval,
        username_mapping_key: instance.mappings.username,
        full_name_mapping_key: instance.mappings.full_name,
        email_mapping_key: instance.mappings.email
      });
    } else {
      reset();
    }
  };

  const handleAddInstance = () => setIsFormOpen(true);
  const handleFormEdit = () => {
    setFormMode('update');
    if (instanceRef.current?.password) setIsPasswordEditable(false);
  };
  const handleFormCancel = () => {
    if (saveRequest.loading) return;
    setTestConnectionResult(null);
    setIsFormOpen(false);
    setIsCaCertificateEditable(false);
    if (hasInstance) {
      setFormMode('view');
    }
    resetForm(instanceRef.current);
  };
  const handleFormSubmit = async (event?: FormEvent) => {
    if (saveRequest.loading) return;
    event?.preventDefault();
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();
    let caCertificateBase64 = '';
    const caCertificateFile = values.ca_certificate?.item(0);
    if (caCertificateFile) {
      const arrayBuffer = await caCertificateFile.arrayBuffer();
      caCertificateBase64 = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
    }
    try {
      const result = await saveRequest.send(
        saveLdapInstance({
          display_name: values.display_name,
          hostname: values.hostname,
          ssl: values.ssl,
          port: values.port || null,
          base_dn: values.base_dn || null,
          filter: values.filter || null,
          user_dn: values.user_dn || null,
          ...(values.sync_enabled !== undefined && { sync_enabled: values.sync_enabled }),
          ...(values.sync_interval !== undefined && {
            sync_interval: Number(values.sync_interval)
          }),
          mappings: {
            ...(values.username_mapping_key && { username: values.username_mapping_key }),
            ...(values.full_name_mapping_key && {
              full_name: values.full_name_mapping_key
            }),
            ...(values.email_mapping_key && { email: values.email_mapping_key })
          },
          ...(isPasswordEditable && { password: values.password || null }),
          ...(caCertificateBase64 && { ca_certificate: caCertificateBase64 })
        })
      );
      resetForm(result);
      instanceRef.current = result;
      setHasInstance(true);
      setIsCaCertificateEditable(false);
      setFormMode('view');
    } catch (error) {
      toast.error(String(error), { autoClose: 5000 });
    }
  };
  const handleEditPassword = () => {
    setIsPasswordEditable(true);
    setValue('password', '');
  };
  const handleEditCaCertificate = () => {
    setIsCaCertificateEditable(true);
    setValue('ca_certificate', undefined);
  };
  const handleTestConnection = async () => {
    if (testConnectionRequest.loading) return;
    const isValid = await trigger(['hostname', 'port', 'base_dn', 'user_dn', 'ca_certificate']);
    if (!isValid) return;
    setTestConnectionResult(null);
    const values = getValues();
    let caCertificateBase64 = '';
    const caCertificateFile = values.ca_certificate?.item(0);
    if (caCertificateFile) {
      const arrayBuffer = await caCertificateFile.arrayBuffer();
      caCertificateBase64 = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
    }
    try {
      await testConnectionRequest.send(
        testLdapConnection({
          hostname: values.hostname,
          ssl: values.ssl,
          ...(values.port && { port: values.port }),
          ...(values.base_dn && { base_dn: values.base_dn }),
          ...(values.user_dn && { user_dn: values.user_dn }),
          ...(isPasswordEditable && values.password && { password: values.password }),
          ...(caCertificateBase64 && { ca_certificate: caCertificateBase64 })
        })
      );
      setTestConnectionResult(true);
    } catch (error) {
      console.error(error);
      setTestConnectionResult(false);
    }
  };

  const handleSyncModalOpen = () => setIsSyncModalOpen(true);
  const handleSyncModalClose = () => {
    if (syncRequest.loading) return;
    setIsSyncModalOpen(false);
  };
  const handleSyncModalSubmit = async () => {
    if (syncRequest.loading) return;
    try {
      await syncRequest.send(syncDeviceUsersViaLDAP());
      setIsSyncModalOpen(false);
      toast.success(t('settings.tiles.ldap.page.sync_modal_toast_success'), { autoClose: 5000 });
    } catch (error) {
      toast.error(String(error), { autoClose: 5000 });
    }
  };

  const init = async () => {
    try {
      const result = await initRequest.send(getCurrentLdapInstance());
      instanceRef.current = result;
      resetForm(result);
      setHasInstance(true);
      setHasCaCertificate(result.has_ca_certificate);
    } catch (err) {
      setHasInstance(false);
      setFormMode('update');
    }
  };

  useEffect(() => {
    void init();
  }, []);

  const sslValue = watch('ssl');
  return (
    <div>
      <Breadcrumbs />
      {hasInstance === false && !isFormOpen && (
        <div className={css.NotConfiguredContainer}>
          <p>{t('settings.tiles.ldap.page.not_configured_title')}</p>
          <Button
            theme="primary"
            onClick={handleAddInstance}
            isDisabled={!isAllowedTo(Permission.EditAdministration)}
          >
            {t('settings.tiles.ldap.page.not_configured_configure_btn')}
          </Button>
        </div>
      )}
      {(hasInstance || isFormOpen) && (
        <form className={css.Form} onSubmit={handleFormSubmit}>
          <div className={css.Header}>
            <h5>{t('settings.tiles.ldap.page.form_title')}</h5>
          </div>
          <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditAdministration)}>
            <TextInput
              label={t('settings.tiles.ldap.page.form_label_display_name')}
              register={register('display_name')}
              errorText={formState.errors.display_name?.message}
              required
              disabled={formMode === 'view'}
            />
            <div>
              <TextInput
                label={t('settings.tiles.ldap.page.form_label_hostname')}
                register={register('hostname')}
                errorText={formState.errors.hostname?.message}
                disabled={formMode === 'view'}
                required
              />
              <TextInput
                label={t('settings.tiles.ldap.page.form_label_port')}
                register={register('port')}
                type={TextInputType.INTEGER}
                errorText={formState.errors.port?.message}
                disabled={formMode === 'view'}
              />
            </div>
            <Checkbox
              register={register('ssl')}
              label={t('settings.tiles.ldap.page.form_label_ssl')}
              disabled={formMode === 'view'}
            />
            {sslValue && (
              <>
                {!isCaCertificateEditable && (
                  <p className={css.HasCertificate}>
                    <span>{t('settings.tiles.ldap.page.form_ca_certificate_has_prefix')}: </span>
                    <span className={css.HasCertificateValue}>
                      {hasCaCertificate
                        ? t('settings.tiles.ldap.page.form_ca_certificate_has_true')
                        : t('settings.tiles.ldap.page.form_ca_certificate_has_false')}
                    </span>
                    {formMode === 'update' && (
                      <a className={css.EditCaCertificateBtn} onClick={handleEditCaCertificate}>
                        {t('settings.tiles.ldap.page.form_ca_certificate_edit_btn')}
                      </a>
                    )}
                  </p>
                )}
                {isCaCertificateEditable && (
                  <FileInput
                    register={register('ca_certificate')}
                    errorText={formState.errors.ca_certificate?.message}
                    label={t('settings.tiles.ldap.page.form_ca_certificate_label')}
                    accept=".pem,.cer"
                  />
                )}
              </>
            )}
            <TextInput
              label={t('settings.tiles.ldap.page.form_label_base_dn')}
              register={register('base_dn')}
              errorText={formState.errors.base_dn?.message}
              disabled={formMode === 'view'}
            />
            <TextInput
              label={t('settings.tiles.ldap.page.form_label_filter')}
              register={register('filter')}
              errorText={formState.errors.filter?.message}
              disabled={formMode === 'view'}
            />
            <div>
              <TextInput
                label={t('settings.tiles.ldap.page.form_label_user_dn')}
                register={register('user_dn')}
                errorText={formState.errors.user_dn?.message}
                disabled={formMode === 'view'}
              />
              <TextInput
                className={css.InputPassword}
                label={t('settings.tiles.ldap.page.form_label_password')}
                type={TextInputType.PASSWORD}
                showPasswordBtn={formMode !== 'view' && isPasswordEditable}
                register={register('password')}
                errorText={formState.errors.password?.message}
                disabled={formMode === 'view' || !isPasswordEditable}
              />
              {formMode === 'update' && !isPasswordEditable && (
                <a className={css.EditPasswordBtn} onClick={handleEditPassword}>
                  {t('settings.tiles.ldap.page.form_edit_password')}
                </a>
              )}
            </div>
          </fieldset>
          {formMode === 'update' && (
            <div className={css.TestConnectionContainer}>
              <Button onClick={handleTestConnection} isLoading={testConnectionRequest.loading}>
                {t('settings.tiles.ldap.page.form_test_connection_btn')}
              </Button>
              {testConnectionResult !== null && (
                <div className={css.TestConnectionResult}>
                  <span>{t('settings.tiles.ldap.page.form_test_connection_result')}: </span>
                  {testConnectionResult && (
                    <span className={css.TestConnectionSuccess}>
                      {t('settings.tiles.ldap.page.form_test_connection_result_success')}
                    </span>
                  )}
                  {!testConnectionResult && (
                    <span className={css.TestConnectionFailure}>
                      {t('settings.tiles.ldap.page.form_test_connection_result_failure')}
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
          {hasInstance && (
            <Collapsible
              className={css.Mappings}
              header={t('settings.tiles.ldap.page.mappings_title')}
              isOpenInit={false}
            >
              <TextInput
                label={t('settings.tiles.ldap.page.mappings_username_label')}
                register={register('username_mapping_key')}
                disabled={formMode === 'view'}
              />
              <TextInput
                label={t('settings.tiles.ldap.page.mappings_display_name_label')}
                register={register('full_name_mapping_key')}
                disabled={formMode === 'view'}
              />
              <TextInput
                label={t('settings.tiles.ldap.page.mappings_email_label')}
                register={register('email_mapping_key')}
                disabled={formMode === 'view'}
              />
            </Collapsible>
          )}
          {hasInstance && (
            <Collapsible
              className={css.Mappings}
              header={t('settings.tiles.ldap.page.sync_title')}
              isOpenInit={false}
            >
              <Checkbox
                label={t('settings.tiles.ldap.page.sync_enabled_label')}
                register={register('sync_enabled')}
                disabled={formMode === 'view'}
              />
              <TextInput
                type={TextInputType.INTEGER}
                label={t('settings.tiles.ldap.page.sync_interval_label')}
                register={register('sync_interval')}
                disabled={formMode === 'view'}
                errorText={formState.errors.sync_interval?.message}
              />
              <Button onClick={handleSyncModalOpen}>
                {t('settings.tiles.ldap.page.sync_manually_btn')}
              </Button>
            </Collapsible>
          )}
          <div className={css.ActionBtnGroup}>
            {formMode === 'view' && (
              <Button
                className={css.ActionBtn}
                theme="primary"
                onClick={handleFormEdit}
                isDisabled={!isAllowedTo(Permission.EditAdministration)}
              >
                {t('settings.tiles.ldap.page.form_edit_btn')}
              </Button>
            )}
            {formMode === 'update' && (
              <>
                <Button onClick={handleFormCancel} className={css.ActionBtn} theme="danger">
                  {t('settings.tiles.ldap.page.form_cancel_btn')}
                </Button>
                <Button
                  className={css.ActionBtn}
                  type="submit"
                  theme="primary"
                  isLoading={saveRequest.loading}
                >
                  {t('settings.tiles.ldap.page.form_save_btn')}
                </Button>
              </>
            )}
          </div>
        </form>
      )}
      <Modal
        isOpen={isSyncModalOpen}
        onRequestClose={handleSyncModalClose}
        onSubmit={handleSyncModalSubmit}
        submitButtonName={t('settings.tiles.ldap.page.sync_modal_submit')}
        title={t('settings.tiles.ldap.page.sync_modal_title')}
        isLoading={syncRequest.loading}
      >
        <p>{t('settings.tiles.ldap.page.sync_modal_text')}</p>
      </Modal>
    </div>
  );
};
